import React from 'react';

// import icHand from 'assets/icons/ic-hand.png';
// import logoImg from 'assets/images/logo.png';
import Container from 'components/organisms/Container';

interface MainLayoutProps {
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => (
  <main className="wrapper">
    <header className="t-mainLayout_header">
      {/* <div className="t-mainLayout_logo">
        <img src={logoImg} alt="logo" />
      </div> */}
    </header>
    <div className="t-mainLayout_body">
      <Container>
        {children}
      </Container>
    </div>
    <footer className="t-mainLayout_footer">
      {/* <Container>
        <img src={icHand} alt="hand" />
      </Container> */}
    </footer>
  </main>
);

export default MainLayout;
